import { useEffect, useState } from "react";
import { getORouterPurchaseAvailability } from "../pages/api";

const usePurchaseAllowedLogic = () => {
  const [purchaseAvailable, setPurchaseAvailable] = useState(null);

  useEffect(() => {
    (async () => {
      const result = (await getORouterPurchaseAvailability()).data;
      setPurchaseAvailable(result.data.successfullOrdersCount < 99999);
    })();
  }, []);

  return { purchaseAvailable };
};

export default usePurchaseAllowedLogic;
