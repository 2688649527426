import React from "react";
import styled from "styled-components";
import Text from "./ui/Text";

export default function MediaSection({ inverted }) {
  return (
    <div style={{}}>
      <Text
        variant="title"
        style={{ color: inverted ? "white" : "black", padding: "24px 0px" }}
      >
        As seen in
      </Text>
      <LogoContainer inverted={inverted}>
        <LogoWrapper href="https://youtu.be/_kWFwJSsfRc">
          <Logo src="/assets/png/fox-business-white.png" />
        </LogoWrapper>
        <LogoWrapper href="https://www.coindesk.com/markets/2021/05/04/forbes-would-be-acquirer-outlines-blockchain-media-strategy/">
          <Logo src="/assets/png/coindesk-white.png" />
        </LogoWrapper>
        <LogoWrapper href="https://www.bloomberg.com/news/articles/2021-05-24/guggenheim-founder-morley-is-backing-a-new-york-blockchain-tower">
          <Logo src="/assets/png/bloomberg-white.png" />
        </LogoWrapper>
        <LogoWrapper href="https://www.reuters.com/business/media-telecom/exclusive-forbes-pursues-spac-talks-amid-new-takeover-interest-sources-2021-04-29/">
          <Logo src="/assets/png/reuters-white.png" />
        </LogoWrapper>
        <LogoWrapper href="https://news.artnet.com/art-world/nft-museum-111-west-57th-1973184">
          <Logo src="/assets/png/artnet-white.png" />
        </LogoWrapper>
        <LogoWrapper href="https://apnews.com/article/beb4c6ce7202894907ceb72658fad320">
          <Logo src="/assets/png/ap-white.png" />
        </LogoWrapper>
      </LogoContainer>
    </div>
  );
}

const LogoContainer = styled.div`
  display: grid;
  --n: 3;
  grid-template-columns: repeat(
    auto-fill,
    minmax(max(150px, 100% / var(--n)), 1fr)
  );

  /* @media screen and (max-width: 992px) {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  } */

  img {
    filter: ${(props) => (props.inverted ? "invert(0%)" : "invert(100%)")};
    opacity: 0.55;

    :hover {
      opacity: 1;
      transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
  }
`;

const Logo = styled.img`
  height: 40px;
  margin: auto;
  text-align: center;
  transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

  @media screen and (max-width: 992px) {
    height: 25px;
  }
`;
const LogoWrapper = styled.a`
  margin-bottom: 40px;
  align-self: center;
  text-align: center;
  transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
`;
