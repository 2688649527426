import React, { useState, useEffect } from "react";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import styles from "../styles/Home.module.css";
import Text from "../components/ui/Text";
import Wrapper from "../components/ui/Wrapper";
import styled from "styled-components";
import Navbar from "../components/layout/navbar";
import Footer from "../components/layout/footer";
import { NextSeo } from "next-seo";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MediaSection from "../components/MediaSection";
import { colorTheme } from "../styles/defaultTheme";
import Link from "next/link";
import Button from "../components/ui/Button";
import { useInView } from "react-intersection-observer";
import { boolean } from "yup";
import { getORouterPurchaseAvailability } from "./api";
import usePurchaseAllowedLogic from "../hooks/usePurchaseAllowedLogic";

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale)),
      // Will be passed to the page component as props
    },
  };
}

function Feature1() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <FeatureSC ref={ref}>
      <Text
        variant="cardcaption"
        className={`color countdown-caption ${inView ? "fade-in1" : ""}`}
      >
        Wireless Web3
      </Text>
      <Text variant="cardtitle" className={`${inView ? "fade-in2" : ""}`}>
        No internet required.
      </Text>
      <Text variant="cardsubtitle" className={`${inView ? "fade-in3" : ""}`}>
        No internet? No problem.
        <br />
        Stay connected and communicate securely.
      </Text>
      <img
        src="/assets/mockups/connect-mockup.webp"
        alt="groups"
        className={`connect ${inView ? "fade-in4" : ""}`}
      />
    </FeatureSC>
  );
}

function Feature2() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <FeatureSC ref={ref}>
      <Text
        variant="cardcaption"
        className={`color countdown-caption ${inView ? "fade-in1" : ""}`}
      >
        Earn Crypto
      </Text>
      <Text variant="cardtitle" className={`${inView ? "fade-in2" : ""}`}>
        Passive Rewards.
      </Text>
      <Text variant="cardsubtitle" className={`${inView ? "fade-in3" : ""}`}>
        Provide coverage.
        <br />
        Generate passive rewards.
      </Text>
      <img
        src="/assets/mockups/rewards-mockup.webp"
        alt="groups"
        className={`${inView ? "fade-in4" : ""}`}
      />
    </FeatureSC>
  );
}

function Feature3() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <FeatureSC id="row" ref={ref}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 12,
          alignItems: "center",
        }}
      >
        <Text
          variant="cardcaption"
          className={`color countdown-caption ${inView ? "fade-in1" : ""}`}
          style={{
            width: "fit-content",
            margin: "auto !important",
            textAlign: "center",
          }}
        >
          Wireless DeFi
        </Text>
        <Text variant="cardtitle" className={`${inView ? "fade-in2" : ""}`}>
          Trade & transact securely
        </Text>
        <Text variant="cardsubtitle" className={`${inView ? "fade-in3" : ""}`}>
          Security with convenience.
          <br />
          Transact without an internet connection.
        </Text>
      </div>
      <img
        src="/assets/mockups/trade-mockup.webp"
        alt="groups"
        className={`${inView ? "fade-in1" : ""}`}
      />
    </FeatureSC>
  );
}

function Feature4() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <FeatureSC ref={ref}>
      <Text
        variant="cardcaption"
        className={`color countdown-caption ${inView ? "fade-in1" : ""}`}
      >
        Decentralized Social
      </Text>
      <Text variant="cardtitle" className={`${inView ? "fade-in2" : ""}`}>
        Web3 social filtering.
      </Text>
      <Text variant="cardsubtitle" className={`${inView ? "fade-in3" : ""}`}>
        Decide what you see.
        <br />
        Connect with the communities you want.
      </Text>
      <img
        src="/assets/mockups/groups-mockup.webp"
        alt="groups"
        className={`${inView ? "fade-in4" : ""}`}
      />
    </FeatureSC>
  );
}

function Feature5() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <FeatureSC ref={ref}>
      <Text
        variant="cardcaption"
        className={`color countdown-caption ${inView ? "fade-in1" : ""}`}
      >
        Find Alpha
      </Text>
      <Text variant="cardtitle" className={`${inView ? "fade-in2" : ""}`}>
        Decentralized Alpha.
      </Text>
      <Text variant="cardsubtitle" className={`${inView ? "fade-in3" : ""}`}>
        Follow the whales.
        <br />
        Trade like a pro.
      </Text>
      <img
        src="/assets/mockups/alpha-group-mockup.webp"
        alt="groups"
        className={`alpha ${inView ? "fade-in4" : ""}`}
      />
    </FeatureSC>
  );
}

export default function Home(props) {
  const { t } = useTranslation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { purchaseAvailable } = usePurchaseAllowedLogic();
  return (
    <>
      <NextSeo
        title="Overline | ōRouter"
        description="Generate passive rewards and get wireless access to Crypto, Digital Collectibles, DeFi, and more directly from your pocket."
        canonical="https://overline.network"
        openGraph={{
          url: "https://overline.network/orouter",
          title: "Overline | ōRouter",
          description:
            "Generate passive rewards and get wireless access to Crypto, Digital Collectibles, DeFi, and more directly from your pocket.",
          images: [
            {
              url: `${process.env.ORIGIN}/orouter_share_preview.jpeg`,
              width: 1200,
              height: 600,
              alt: "ōRouter Image",
              type: "image/jpeg",
            },
          ],
          site_name: "https://overline.network",
        }}
        twitter={{
          handle: "@overlinenetwork",
          site: "@overlinenetwork",
          description:
            "Generate passive rewards and get wireless access to Crypto, Digital Collectibles, DeFi, and more directly from your pocket.",
          cardType: "summary_large_image",
        }}
      />

      <Navbar
        bannerText={t("nothome.banner")}
        bannerBold={t("nothome.bannerspan")}
        bannerLink="/checkout"
        banner={true}
      />
      <div
        style={{ backgroundColor: "#F2F4F7", padding: 0 }}
        className={styles.main}
        ref={ref}
      >
        <div style={{ width: "100%" }}>
          <Wrapper style={{ margin: "auto" }}>
            <Content
              style={{ margin: "auto", paddingBottom: "48px", paddingTop: 24 }}
            >
              <div className="hero-container">
                <video
                  width="auto"
                  style={{ width: "100%" }}
                  autoPlay={true}
                  loop={true}
                  playsInline={true}
                  preload={"auto"}
                  muted
                  id="hero-bg"
                  poster="/assets/animations/kaleidoscope-poster.png"
                  className={inView ? `fade-in1` : ""}
                >
                  <source
                    src="/assets/animations/kaleidoscope.mp4"
                    type="video/mp4"
                  />
                </video>
                <div id="content-wrapper">
                  <Text
                    variant="title"
                    className={`gradient ${inView ? "fade-in2" : ""}`}
                  >
                    Meet the ōRouter
                  </Text>
                  <Text
                    variant="cardtext"
                    className={`${inView ? "fade-in3" : ""}`}
                  >
                    Limitless P2P connectivity.
                    <br />
                    Stay connected even in the most challenging environments.
                    <br />
                    <span>No internet service required.</span>
                  </Text>
                  <Link href={"/checkout"} passHref>
                    <Button
                      loading={purchaseAvailable === null}
                      disabled={purchaseAvailable === false}
                      type="button"
                      buttonText={
                        purchaseAvailable ? "Buy ōRouter" : "Sold Out"
                      }
                      style={{
                        height: "100%",
                        maxWidth: 250,
                        margin: "auto",
                      }}
                      className={`sub ${inView ? "fade-in4" : ""}`}
                      white={false}
                      inverted={true}
                    />
                  </Link>
                  <Text
                    variant="cardtext"
                    className={`${inView ? "fade-in4" : ""}`}
                  >
                    Supplies are limited.
                  </Text>

                  <video
                    width="auto"
                    autoPlay
                    loop
                    playsInline
                    preload={"auto"}
                    muted
                    poster="/assets/animations/spinning-node-transparent-poster.png"
                  >
                    <source
                      src="/assets/animations/node/spinning-node-transparent-hevc-safari.mp4"
                      type='video/mp4; codecs="hvc1"'
                    />
                    <source
                      src="/assets/animations/node/spinning-node-transparent-vp9-chrome.webm"
                      type="video/webm"
                    />
                  </video>
                </div>
              </div>

              <MediaSection inverted={false} />
              <div className="features">
                <Feature1 />
                <Feature2 />
              </div>
              <div className="features" id="fullwidth">
                <Feature3 />
              </div>
              <div className="features">
                <Feature4 />
                <Feature5 />
              </div>
            </Content>

            {/* <Text variant="title" style={{ color: "white" }}>
              Technical Specifications
            </Text> */}
            {/* <div style={{ paddingBottom: 80 }}>
              <table className="spec-table">
                <tr>
                  <td className="bold">Dimensions</td>
                  <td>100.5mm x 19.5mm x 65.5mm</td>
                </tr>
                <tr>
                  <td className="bold">Weight (Planned)</td>
                  <td>0.155 kg</td>
                </tr>
                <tr>
                  <td className="bold">Range</td>
                  <td>
                    1.6 sq km obstructed (dense urban cities)
                    <br />
                    64 sq km unobstructed (flat, rural)
                  </td>
                </tr>
                <tr>
                  <td className="bold">Mobile App Compatability</td>
                  <td>
                    iOS
                    <br />
                    Android
                  </td>
                </tr>
                <tr>
                  <td className="bold">Antennas</td>
                  <td>4</td>
                </tr>

                <tr>
                  <td className="bold">Transmission Protocol</td>
                  <td>
                    LORA
                    <br />
                    Bluetooth
                    <br />
                    Proof-of-Distance
                  </td>
                </tr>
                <tr>
                  <td className="bold">Dust Sealed | Water Resistant</td>
                  <td>Yes (Pending Rating)</td>
                </tr>
                <tr>
                  <td className="bold">Exposed Port</td>
                  <td>1x USB-C</td>
                </tr>
                <tr>
                  <td style={{ borderBottom: "none" }} className="bold">
                    Included in Package
                  </td>
                  <td style={{ borderBottom: "none" }}>
                    1x ōRouter
                    <br />
                    1x USB-C cable
                  </td>
                </tr>
              </table>
            </div> */}
          </Wrapper>
        </div>
      </div>
      <ToastContainer />
      <Footer inverted={false} />
    </>
  );
}

const Content = styled.div`
  max-width: 1280px;

  .hero-container {
    background: black;
    border-radius: 18px;
    position: relative;
    height: 640px;
    display: flex;
    align-items: flex-end;
    padding: 0px;
    overflow: hidden;

    @media only screen and (max-width: 776px) {
      height: 400px;
    }

    #hero-bg {
      position: absolute;
      right: 0px;
      left: 0px;
      top: 0px;
      bottom: 0px;
      object-fit: cover;
      width: 100% !important;
      height: 100% !important;
      opacity: 0.65;
      min-height: 640px;
      border-radius: 18px;

      @media only screen and (max-width: 776px) {
        min-height: 400px !important;
        height: 100% !important;
      }
    }

    h1 {
      text-align: center;
      color: white;
    }

    #content-wrapper {
      z-index: 50;
      position: relative;
      width: 100%;
      align-items: center;
      padding-bottom: 40px;
      @media only screen and (min-width: 776px) {
        button {
          margin-top: 36px !important;
        }
      }

      .gradient {
        font-size: 3.5rem;
      }

      @media only screen and (max-width: 776px) {
        padding-left: 24px;
        padding-right: 24px;
        .gradient {
          font-size: 2.25rem;
        }

        span {
          display: none;
        }

        button {
          margin-top: 12px;
        }
      }

      h1 {
        margin-bottom: 12px;
      }

      a {
        color: white;
        text-align: center;
        margin: auto;
        display: block;
        margin-top: 12px !important;

        :hover {
          text-decoration: underline;
        }
      }

      video {
        position: absolute;
        margin: auto;
        right: 0;
        left: 0;
        bottom: 140px;
        z-index: -1;
        height: 350px;

        @media only screen and (max-width: 776px) {
          height: 200px;
          bottom: 120px;
        }
      }
    }
  }

  .features {
    margin-top: 24px;
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(2, 1fr);

    &#fullwidth {
      grid-template-columns: repeat(1, 1fr);
    }

    @media only screen and (max-width: 776px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .feature-carousel {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    margin-top: 24px;

    @media only screen and (max-width: 776px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

const FeatureSC = styled.div`
  background: white;
  border-radius: 18px;
  padding: 40px;
  gap: 12px;
  padding-bottom: 0px !important;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;

  h1 {
    margin: 0px !important;
  }

  img {
    height: auto;
    margin: auto;
    margin-top: 12px;
    width: 100%;
    bottom: 0px;

    &.connect {
      width: 90%;
    }

    &.alpha {
      width: 80%;
    }
  }

  @media only screen and (max-width: 1280px) {
    img {
      height: 100%;
      max-height: 400px;
      margin: auto;
      margin-top: 12px;
    }
  }

  @media only screen and (max-width: 1080px) {
    padding: 24px;
    padding-top: 40px;
    img {
      width: 100%;
      margin: auto;
      margin-top: 12px;
    }
  }

  &#row {
    flex-direction: row;
    justify-content: space-between;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;

    @media only screen and (max-width: 1080px) {
      flex-direction: column !important;
      grid-template-columns: repeat(1, 1fr);
    }
    div {
      width: 100%;
    }
    img {
      width: 100%;
    }
  }
`;

const MiniFeatureSC = styled.div`
  background: white;
  border-radius: 18px;
  padding: 24px;
  padding-top: 40px;
  gap: 12px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;

  h1 {
    margin: 0px !important;
  }

  img {
    width: 100%;
    margin: auto;
    margin-top: 12px;
  }
`;

const SubscribeSC = styled.div`
  background: white;
  border-radius: 18px;
  display: flex;
  align-items: center;
  padding: 40px;
  margin-top: 24px;

  .content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    text-align: center;
    margin: auto;

    h1 {
      margin: 0px !important;
    }

    a {
      color: ${colorTheme.colors.primary.primary100};
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
